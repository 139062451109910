/* eslint-disable import/no-duplicates */
import Vue from 'vue'
import wysiwyg from 'vue-wysiwyg'
import { defineAsyncComponent } from 'vue'
import SearchMenu from './search/search-menu/index.vue'
import Header from './header/index.vue'
import SearchMembershipOrganization from './search/search-membership-organization/index.vue'
import DisplayCompanyProperties from './display-company-properties/index.vue'
import SearchSubMenu from './search/search-sub-menu/index.vue'
import SearchAgentMenu from './search/search-agent-menu/index.vue'
import StateAction from './state-action/index.vue'
import StateSorting from './state-sorting/index.vue'
import StateToggle from './state-toggle/index.vue'
import SearchModal from './search/search-modal/index.vue'
import SearchResult from './search/search-result/index.vue'
import SearchMap from './search/search-map/index.vue'
import cMessages from './messages/index.vue'
import cMessagesOverlay from './messages/messages-overlay/index.vue'
import cCtaButton from './cta-button/index.vue'
import vSlider from 'vue-slider-component'
import ProfileButton from './header/profile-button/index.vue'
import ImpersonateButton from './header/impersonate-button/index.vue'
import PropertyCardSmall from './search/search-result/property-card-small/index.vue'
import Spot from './search/search-result/property-spot/index.vue'

import Modal from './modal/index.vue'
import Video from './video/index.vue'
import SlickSlider from './slider/index.vue'
import LoadingAnimation from './loading-animation/index.vue'
import PropertyCard from './search/search-result/property-card/index.vue'
import VueMask from 'v-mask'
import ProfileInformation from './profile/profile-information/index.vue'
import ProfileNotifications from './profile/profile-notifications/index.vue'
import ProfileMemberships from './profile/profile-memberships/index.vue'
import ProfileEducation from './profile/profile-education/index.vue'
import LoginFlow from './login-flow/index.vue'
import cNotificationInline from './notifications/notification-inline/index.vue'
import ProfileMenu from './navigation/profile-menu/index.vue'
import FavoritesButton from './favorites/favorites-button/index.vue'
import Favorites from './favorites/index.vue'
import ShareButton from './share-button/index.vue'
import WaitingList from './waiting-list/index.vue'
import MyWaitingLists from './my-waitinglists/index.vue'
import HighlightedResultsSlider from './search/search-result/highlighted-results-slider/index.vue'
import MembershipOrganizationSlider from './membership-organization-slider/index.vue'
import Pagination from './pagination/index.vue'
import TabNavigation from './tab-navigation/index.vue'
import Vuelidate from 'vuelidate'
import ShowMore from './show-more/index.vue'
import Toast from './toast/index.vue'
import Table from './table/index.vue'
import PropertyManagement from './property-management/index.vue'
import ResidenceManagement from './residence-management/index.vue'
import AdvertManagement from './advert-management/index.vue'
import ApplicationsManagement from './applications-management/index.vue'
import cParametersManagement from './parameters-management/index.vue'
import cCompaniesManagement from './companies-management/index.vue'
import cOffersManagement from './offers-management/index.vue'
import cUsersManagement from './users-management/index.vue'
import cUsersStatusChange from './users-status-change/index.vue'
import cAdministratorsManagement from './administrators-management/index.vue'
import Ub4PropertyManagement from './ub4-property-management/index.vue'
import Ub4FacilitiesManagement from './ub4-facilities-management/index.vue'
import Ub4SumCodesManagement from './ub4-sumcodes-management/index.vue'
import cApplicantsManagement from './applicants-management/index.vue'
import cVerificationJobsManagement from './verification-jobs-management/index.vue'
import cAdvertsScreening from './adverts-screening/index.vue'
import cTabs from './tabs/index.vue'
import cTab from './tabs/tab/index.vue'
import cImportData from './import-data/index.vue'
import cAdvertApply from './advert-apply/index.vue'
import cMyOffers from './my-offers/index.vue'
import cMyPage from './my-page/index.vue'
import cReport from './report/index.vue'
import cWorthToKnowBox from './worth-to-know-box/index.vue'
import cFacilitiesManagement from './facilities-management/index.vue'
import cPropertyTypesManagement from './property-types-management/index.vue'
import cContactForm from './contact-form/index.vue'

// FILTER COMPONENTS
import cFilterTypeahead from './search/search-filters/filter-typeahead/index.vue'
import cFilterInputNumber from './search/search-filters/filter-input-number/index.vue'
import cFilterInputSlider from './search/search-filters/filter-input-slider/index.vue'
import cFilterInputChecklist from './search/search-filters/filter-input-checklist/index.vue'
import cFilterInputRadiolist from './search/search-filters/filter-input-radiolist/index.vue'
import cFilterInputSelect from './search/search-filters/filter-input-select/index.vue'
import cFilterInputType from './search/search-filters/filter-input-type/index.vue'
import cFilterSection from './search/search-filters/filter-section/index.vue'

import * as VueGoogleMaps from 'vue2-google-maps'
import vSelect from 'vue-select'
import OpenIndicator from './vSelect/OpenIndicator.vue'
import Deselect from './vSelect/Deselect.vue'

// PROPERTY COMPONENTS
import cPropertyHero from './property/property-hero/index.vue'
import cPropertyFacilities from './property/property-facilities/index.vue'
import cPropertyLabels from './property/property-labels/index.vue'
import PropertyMap from './property-map/index.vue'
import SinglePropertyMap from './property-map/single-property-map/index.vue'
import cPropertyBluebox from './property-bluebox/index.vue'

vSelect.props.components.default = () => ({ OpenIndicator, Deselect })
Vue.use(wysiwyg, { paragraphSeparator: 'p' })
Vue.component('c-header', Header)
Vue.component('c-profile-menu', ProfileMenu)
Vue.component('c-favorites', Favorites)
Vue.component('c-favorites-button', FavoritesButton)
Vue.component('c-share-button', ShareButton)
Vue.component('c-spot', Spot)
Vue.component('c-property-card-small', PropertyCardSmall)
Vue.use(VueMask)
Vue.use(Vuelidate)
Vue.component('c-property-card', PropertyCard)
Vue.component('c-modal', Modal)
Vue.component('c-video', Video)
Vue.component('c-slick-carousel', SlickSlider)
Vue.component('v-slider', vSlider)
Vue.component('c-cta', cCtaButton)
Vue.component('c-loading-animation', LoadingAnimation)
Vue.component('c-search-menu', SearchMenu)
Vue.component('c-search-membership-organization', SearchMembershipOrganization)
Vue.component('c-display-company-properties', DisplayCompanyProperties)
Vue.component('c-search-sub-menu', SearchSubMenu)
Vue.component('c-search-agent-menu', SearchAgentMenu)
Vue.component('c-state-action', StateAction)
Vue.component('c-state-sorting', StateSorting)
Vue.component('c-state-toggle', StateToggle)
Vue.component('c-search-modal', SearchModal)
Vue.component('c-search-result', SearchResult)
Vue.component('c-search-map', SearchMap)
Vue.component('c-messages', cMessages)
Vue.component('c-messages-overlay', cMessagesOverlay)
Vue.component('c-profile-button', ProfileButton)
Vue.component('c-impersonate-button', ImpersonateButton)
Vue.component('c-profile-information', ProfileInformation)
Vue.component('c-profile-notifications', ProfileNotifications)
Vue.component('c-profile-memberships', ProfileMemberships)
Vue.component('c-profile-education', ProfileEducation)
Vue.component('c-login-flow', LoginFlow)
Vue.component('c-notification-inline', cNotificationInline)
Vue.component('c-waiting-list', WaitingList)
Vue.component('c-my-waitinglists', MyWaitingLists)
Vue.component('c-highlighted-results-slider', HighlightedResultsSlider)
Vue.component('c-membership-organization-slider', MembershipOrganizationSlider)
Vue.component('c-pagination', Pagination)
Vue.component('c-tab-navigation', TabNavigation)
Vue.component('c-show-more', ShowMore)
Vue.component('c-toast', Toast)
Vue.component('c-table', Table)
Vue.component('c-property-management', PropertyManagement)
Vue.component('c-residence-management', ResidenceManagement)
Vue.component('c-advert-management', AdvertManagement)
Vue.component('c-applications-management', ApplicationsManagement)
Vue.component('c-parameters-management', cParametersManagement)
Vue.component('c-companies-management', cCompaniesManagement)
Vue.component('c-offers-management', cOffersManagement)
Vue.component('c-users-management', cUsersManagement)
Vue.component('c-users-status-change', cUsersStatusChange)
Vue.component('c-administrators-management', cAdministratorsManagement)
Vue.component('c-ub4-property-management', Ub4PropertyManagement)
Vue.component('c-ub4-facilities-management', Ub4FacilitiesManagement)
Vue.component('c-ub4-sumcodes-management', Ub4SumCodesManagement)
Vue.component('c-applicants-management', cApplicantsManagement)
Vue.component('c-verification-jobs-management', cVerificationJobsManagement)
Vue.component('c-adverts-screening', cAdvertsScreening)
Vue.component('c-tabs', cTabs)
Vue.component('c-tab', cTab)
Vue.component('c-import-data', cImportData)
Vue.component('c-advert-apply', cAdvertApply)
Vue.component('c-my-offers', cMyOffers)
Vue.component('v-select', vSelect)
Vue.component('c-my-page', cMyPage)
Vue.component('c-report', cReport)
Vue.component('c-worth-to-know', cWorthToKnowBox)
Vue.component('c-facilities-management', cFacilitiesManagement)
Vue.component('c-property-types-management', cPropertyTypesManagement)
Vue.component('c-contact-form', cContactForm)

// FILTER COMPONENTS
Vue.component('c-filter-typeahead', cFilterTypeahead)
Vue.component('c-filter-input-number', cFilterInputNumber)
Vue.component('c-filter-input-slider', cFilterInputSlider)
Vue.component('c-filter-input-checklist', cFilterInputChecklist)
Vue.component('c-filter-input-radiolist', cFilterInputRadiolist)
Vue.component('c-filter-input-select', cFilterInputSelect)
Vue.component('c-filter-input-type', cFilterInputType)
Vue.component('c-filter-section', cFilterSection)

// PROPERTY COMPONENTS
Vue.component('c-property-hero', cPropertyHero)
Vue.component('c-property-map', PropertyMap)
Vue.component('c-single-property-map', SinglePropertyMap)
Vue.component('c-property-bluebox', cPropertyBluebox)
Vue.component('c-property-facilities', cPropertyFacilities)
Vue.component('c-property-labels', cPropertyLabels)

Vue.component(
  'c-pfa-advisor-association-form',
  defineAsyncComponent(() => import('./pfa-advisor-association-form/index.vue'))
)
// Vue.component(
//   'c-property-map',
//   defineAsyncComponent(() => import('./property-map/index.vue'))
// )
// Vue.component(
//   'c-single-property-map',
//   defineAsyncComponent(() => import('./property-map/single-property-map/index.vue'))
// )
// Vue.component(
//   'c-property-hero',
//   defineAsyncComponent(() => import('./property/property-hero/index.vue'))
// )
// Vue.component(
//   'c-property-facilities',
//   defineAsyncComponent(() => import('./property/property-facilities/index.vue'))
// )
// Vue.component(
//   'c-property-labels',
//   defineAsyncComponent(() => import('./property/property-labels/index.vue'))
// )
// Vue.component(
//   'c-property-bluebox',
//   defineAsyncComponent(() => import('./property-bluebox/index.vue'))
// )
// PROPERTY COMPONENTS
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyANdXeftSqVwEqXacNklNEYeVJFxOh3EPw'
  }
})
// Vue.component('GmapMap', VueGoogleMaps.Map)
// Vue.component('GmapMarker', VueGoogleMaps.Marker)
// Vue.component('GmapInfoWindow', VueGoogleMaps.InfoWindow)
